<template>
  <div>
    <!-- 頭部組件 -->
    <nav-header :type="1"></nav-header>

    <div class="app-container confirmDetection">
      <div class="box">
        <h1 class="box-h1">請再次確認</h1>
        <p class="box-p">
          請確認您的耳機、麥克風、視訊設備已插入電腦中的正確插孔，<br />
          並確認沒有設定靜音後，點選「NEXT」。
        </p>
        <div class="box-button" @click="next">
          NEXT
          <img src="@/assets/image/icon/icon-nexts.png" alt="">
        </div>
      </div>
    </div>

    <!-- 底部組件 -->
    <nav-footer></nav-footer>
  </div>
</template>

<script>
export default {
  name: 'ConfirmDetection',
  methods: {
    // 下一步
    next() {
      this.$router.push({
        path: '/headphoneDetection'
      })
    },
  }
}
</script>

<style lang="less" scoped>
.confirmDetection {
  display: flex;
  align-items: center;
  .box {
    margin: 90px auto 80px;
    width: 1360px;
    height: 555px;
    background-image: url('../../assets/image/hardware/confirmDetection.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    .box-h1 {
      padding: 117px 0 36px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 54px;
      text-align: center;
      color: #FFFFFF;
    }
    .box-p {
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 43px;
      text-align: center;
      color: #FFFFFF;
    }
    .box-button {
      cursor: pointer;
      margin: 48px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 56px;
      background: #FFFFFF;
      border-radius: 8px;
      font-family: "Microsoft JhengHei","微軟正黑體";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #0C4FA2;
      img {
        margin-left: 6px;
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>